.dash-card-cus {
    background: linear-gradient(135deg, #c56cd6 0%, #3425af 100%);
}

.dash-text-cus {
    font-size: 10px;
    font-weight: bold;
    color: white;
}

.canvas-custom {
    margin-top: 5px;
    padding-bottom: 5px;
    display: block;
    width: 291px;
    height: 150px;
}

.canvas-custom1 {
    margin-top: 5px;
    padding-bottom: 5px;
    display: block;
    width: 291px;
    height: 169px;
}

.payment-div-cus {
    position: absolute;
    inset: 0px;
    overflow: hidden;
    pointer-events: none;
    visibility: hidden;
    z-index: -1;
}

.char-div {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;
    visibility: hidden;
    z-index: -1;
}

.div-chart {
    position: absolute;
    width: 1000000px;
    height: 1000000px;
    left: 0;
    top: 0;
}

div-monitor-shrink {
    position: absolute;
    width: 200%;
    height: 200%;
    left: 0;
    top: 0;
}

/*------------academicView & FeeStructureView------------*/
.table-td-style {
    font-weight: bold;
}

.lable-style-dues-rep {
    color: red;
    font-size: 14px;
}

.loaderTheme-sm {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #7A2682;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.opacity {
    opacity: 0.4;
}

/*---------4 colom rectangle------*/
.row-p {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.cfc1 {
    background: linear-gradient(to right, #fe9365, #feb798);
}

.cfc2 {
    background: linear-gradient(to right, #0ac282, #0df3a3);
}

.cfc3 {
    background: linear-gradient(to right, #fe5d70, #fe909d);
}

.cfc4 {
    background: linear-gradient(to right, #01a9ac, #01dbdf);
}

.card-block {
    padding: 1.25rem;
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
}

.col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.text-c-yellow {
    color: #fe9365;
}

.text-c-green {
    color: #0ac282;
}

.text-c-pink {
    color: #fe5d70;
}

.text-c-blue {
    color: #01a9ac;
}

.f-w-600 {
    font-weight: 600;
    font-size: 1.5rem;
}

.m-b-0 {
    margin-bottom: 0;
}

.col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.f-28 {
    font-size: 28px;
}

.card1 {
    margin-top: 30px;
    background-color: #fff;
}

.i-text {
    color: red;
}

.td-text {
    font-weight: bold;
}

/*------page loader-----*/
.load {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 9999;
    /* background: url(../img/dot1loader.gif) no-repeat center center rgba(255,255,255,.8); */
}

/*  use ref content css fix----*/
.dropDwnFix {
    font-size: .8rem;
}

/*-------badge text size----*/
.texSize {
    font-size: 11px;
}

/*-----------scrollBar---------*/
.cardscroll {
    max-height: 490px;
    overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
    width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.966);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #e4e1e1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(173, 175, 173);
}

.formBorder {
    border: 1px solid #ced4da;
}

.ani {
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.errMessage {
    color: rgb(252, 63, 63);
    font-size: 10px;
    position: absolute;
    margin-left: 6px;
}

.form-hgt {
    height: 30px;
}

.style4toggle {
    position: absolute;
    transform: translate3d(-5px, 31px, 0px);
    top: -9%;
    left: 76%;
    will-change: transform;
    min-width: 7rem;
}

.style4toggle1 {
    position: absolute;
    transform: translate3d(-5px, 31px, 0px);
    top: -9%;
    left: 67%;
    will-change: transform;
    min-width: 7rem;
}

.feesettingTogle {
    position: absolute;
    transform: translate3d(-5px, 31px, 0px);
    top: -7%;
    left: 72%;
    will-change: transform;
    min-width: 7rem;
}

.dotdotcls {
    background: grey;
    border-radius: 50%;
    height: 5px;
    width: 5px;
    margin: 1px;
}

.due_reports_Togle {
    position: absolute;
    transform: translate3d(-5px, 31px, 0px);
    top: -20%;
    left: 82%;
    will-change: transform;
    min-width: 7rem;
}

/*---------------------------------------modal css-------------*/
.modal-container {

    display: none;
    padding-right: 15px;
    position: fixed;
    top: 38%;
    left: 49%;
    z-index: 1050;
    width: 50%;
    height: 60%;
    outline: 0;
}


.overlay {
    position: fixed;
    /* Sit on top of the page content */
    display: none;
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Black background with opacity */
    z-index: 400;
    /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer;
    /* Add a pointer on hover */
    filter: blur(2px);
    -webkit-filter: blur(2px);
    backdrop-filter: blur(1px);
}

.capi {
    text-transform: capitalize;
}

.content {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.collapsible {
    background-color: #fff;
    color: rgb(26, 26, 26);
    cursor: pointer;
    padding: 8px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
}

.active,
.collapsible:hover {
    background-color: #ddd;
}


.collapsible:after {
    content: '\002B';
    color: rgb(26, 26, 26);
    font-weight: bold;
    float: right;
    margin-left: 5px;
    border: none;
}

.active:after {
    content: '\2212';
}
.transtions{
    width:100px;
    transition: 1000ms;
}
.capi{
    text-transform: capitalize;
}
